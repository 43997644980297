import React from "react"
import PropTypes from "prop-types"
import Layout from "../layouts/zh"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import "../layouts/index.css"
import ListMainLayout from '../layouts/listmain'

const Tags = ({ pageContext, data, location }) => {
  const { tag, lang } = pageContext

  return (
    <Layout location={location}>
      <SEO title={tag} description={`用${tag}过滤的文章。`} lang="zh" />
      <ListMainLayout data={data} langKey={lang} tag={tag}/>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String!, $lang: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] } }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            langKey
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            category
            tags
            author
            price
          }
          excerpt
        }
      }
    }
  }
`
